import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Q4E from '../components/Q4E';
import { Q4E_ONBOARDING_URL } from '../constants';

const QuashedForEmployers = () => {
  return (
    <Layout
      footerTitle="Get Quashed for Employers"
      footerSubtitle="Don’t waste anymore time, get Quashed!"
      footerCtaButtonProps={{
        target: '_blank',
        href: Q4E_ONBOARDING_URL,
        text: 'Learn more',
      }}
      signUpUrl={Q4E_ONBOARDING_URL}
    >
      <SEO title="Quashed for Employers" />
      <Q4E version={3} />
    </Layout>
  );
};

export default QuashedForEmployers;
