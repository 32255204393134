import React from 'react';
import classnames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styles from './styles/Q4E.module.scss';
import Q4ASvg from '../images/q/q-q4a.svg';
import Q4A2Svg from '../images/q/q-q4a2.svg';
import FamilySvg from '../images/v2/family-yellow.svg';
import LinkButton from './LinkButton';
import { Q4E_ONBOARDING_URL } from '../constants';
import { Scroll } from './Animation';

const v2ScrollOptions = { disabled: true };

const header = (
  <div className={styles.header}>
    <div className="container">
      <div className={styles.title}>Quashed for Employers</div>
      <div className={styles.subheader}>
        Keep workplace insurance benefits front of mind
      </div>
    </div>
  </div>
);

const Section1 = ({ version, onboardingUrl }) => (
  <div className={classnames('container', styles.section)}>
    {version === 2 ? <FamilySvg /> : <Q4ASvg className={styles.q4aSvg} />}
    <div className={styles.title}>
      “What!!? I have free insurance from work...?”
    </div>
    <div className={styles.subtitle}>
      <b>- Your employee</b>
    </div>
    <div className={styles.subtitle}>
      Increasingly employers offer insurance benefits to staff. But when we
      asked, 75% of employees don’t know what they have. So, Quashed is here to
      help bring clarity to your employees insurance and support their financial
      wellbeing. Quashed is a first-of-its-kind platform empowering kiwis to
      help them take back control of their insurances in one place.
    </div>
    <ul className={styles.list}>
      <li>
        <i className="fa-solid fa-check-circle" />
        <div>
          <b>{'Empower your employees financial well-being. '}</b>
          <br />
          Make it easy and clear for your staff to see the insurance benefits
          they have.
        </div>
      </li>
      <li>
        <i className="fa-solid fa-check-circle" />
        <div>
          <b>
            {
              'Our platform experience complements any insurance benefits you have. '
            }
          </b>
          <br />
          It’s quick and easy to launch Quashed to your organisation and staff.
        </div>
      </li>
      <li>
        <i className="fa-solid fa-check-circle" />
        <div>
          <b>
            {
              'Join us on our mission to help thousands of Kiwis to take back control. '
            }
          </b>
          <br />
          Play your part in helping more Kiwis protect the things that matter to
          them, helping them bounce back when things go wrong.
        </div>
      </li>
    </ul>
    <LinkButton
      href={onboardingUrl}
      background="#34363E"
      className={styles.button}
      track
    >
      Learn more
    </LinkButton>
  </div>
);

const Section2 = ({ images, version }) => {
  return (
    <Scroll
      className={styles.section2}
      options={version === 2 && v2ScrollOptions}
    >
      <div className={classnames('container', styles.section)}>
        <div className={classnames(styles.title, styles.blue)}>
          Our proud partners
        </div>
        <div className={styles.quote}>
          <Image
            className={styles.quoteImg}
            fluid={images.xero.childImageSharp.fluid}
          />
          <div className={styles.quoteText}>
            <div>
              <b>
                “Insurance has always been murky and not many people understand
                how they are protected, Quashed makes it easy to view all their
                personal insurances including ones provided by Xero.”
              </b>
            </div>
            <div>- Ryan Ghisi, GM Remuneration and Rewards</div>
          </div>
        </div>
        <div className={styles.quoteRow}>
          <div className={styles.blueQuote}>
            <b>
              “I knew I had some staff insurance benefits, but wasn't clear what
              I had from Xero until now.”
            </b>
          </div>
          <div className={styles.blueQuote}>
            <b>
              “It's been easy to use and helps me to consolidate all my
              insurances. Great features like seeing free insurance benefits
              from my credit card as well.”
            </b>
          </div>
        </div>
      </div>
    </Scroll>
  );
};

const Section3 = ({ onboardingUrl }) => {
  return (
    <div className={styles.greyBg}>
      <div className={classnames('container', styles.section)}>
        <div className={classnames(styles.title, styles.blue)}>
          Hear from one of our partners
        </div>
        <div className={styles.subtitle}>
          Xero's GM, Ryan Ghisi: "As soon as you walked us through your product,
          it was one of those light bulb moments...
        </div>
        <div className={styles.youtube}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/jZcS0wxFW8k"
            title="Quashed for Employers - Xero Case Study"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <LinkButton
          href={onboardingUrl}
          background="#34363E"
          className={styles.button}
          track
        >
          Learn more
        </LinkButton>
      </div>
    </div>
  );
};

const Footer = ({ version, onboardingUrl }) => (
  <Scroll
    className={classnames('container', styles.section, styles.footer)}
    options={version === 2 && v2ScrollOptions}
  >
    <Q4A2Svg />
    <div className={classnames(styles.title, styles.blue)}>
      Peace of mind & Financial wellbeing for your employees
    </div>
    <div className={styles.subtitle}>
      Click below to learn more about Quashed for Employers.
    </div>
    <LinkButton
      href={onboardingUrl}
      background="#34363E"
      className={styles.button}
      track
    >
      Learn more
    </LinkButton>
  </Scroll>
);

const Q4E = ({ version }) => {
  const images = useStaticQuery(graphql`
    query {
      q4e: file(relativePath: { eq: "q4e.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      xero: file(relativePath: { eq: "brands/xero.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classnames(version === 2 && styles.v2)}>
      {header}
      <Section1 version={version} onboardingUrl={Q4E_ONBOARDING_URL} />
      <Section2
        version={version}
        onboardingUrl={Q4E_ONBOARDING_URL}
        images={images}
      />
      <Section3 onboardingUrl={Q4E_ONBOARDING_URL} images={images} />
      <Footer onboardingUrl={Q4E_ONBOARDING_URL} />
    </div>
  );
};

export default Q4E;
